.my-destinations-container {
  margin: 20px;
  text-align: center;
}

.search-input {
  margin-bottom: 10px;
  padding: 5px;
}

.destinations-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto;
}

.destinations-table th,
.destinations-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  max-height: 100px;
}

.destinations-table th {
  cursor: pointer;
  background-color: #f2f2f2;
}

.destinations-table th:hover {
  background-color: #ddd;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-page {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.pagination-previous,
.pagination-next {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.pagination-previous:hover,
.pagination-next:hover,
.pagination-page.active {
  color: #fff;
  background-color: #007bff;
  border-radius: 50%;
  padding: 8px;
}
