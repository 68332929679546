:root {
    --header-height    : 3rem;
    --nav-width        : 68px;
    --first-color      : #4723D9;
    --first-color-light: #747474;
    --white-color      : #e0e0e0;
    --dark-color       : #000000;
    --z-fixed          : 1001
}

a {
    cursor: pointer;
}

.btn-a-link {
    background: transparent;
    border    : none;
}

*,
::before,
::after {
    box-sizing: border-box
}

body {
    position     : relative;
    /* margin    : var(--header-height) 0 0 0; */
    margin       : 0 0 0 0;
    padding      : 0;
    font-size    : var(--normal-font-size);
    transition   : .5s
}

a {
    text-decoration: none
}

.header {
    width             : 100%;
    height            : var(--header-height);
    position          : fixed;
    top               : 0;
    left              : 0;
    display           : flex;
    /* align-items    : center; */
    /* justify-content: space-between; */
    padding           : 0 1rem;
    z-index           : var(--z-fixed);
    transition        : .5s;
    flex-direction    : column;
    justify-content   : center;
    align-items       : stretch;
}

.header_toggle {
    font-size: 1.5rem;
    cursor   : pointer
}

.header_img {
    width          : 50px;
    height         : 50px;
    display        : flex;
    justify-content: center;
    border-radius  : 50%;
    overflow       : hidden
}

.header_img img {
    width: 50px
}

.l-navbar {
    position  : fixed;
    width     : var(--nav-width);
    height    : calc(100vh - var(--header-height));
    top       : calc(15px + var(--header-height));
    right     : -30%;
    padding   : .5rem 0 0 0;
    transition: .5s;
    z-index   : var(--z-fixed+1)
}

#contMap {
    position: fixed;
    height  : calc(100vh - var(--header-height));
    top     : calc(15px + var(--header-height));
    width   : calc(100% - var(--nav-width));
}

.nav {
    height         : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    overflow       : hidden
}

.nav_logo,
.nav_link {
    display              : grid;
    grid-template-columns: max-content max-content;
    align-items          : center;
    column-gap           : 1.22rem;
    padding              : .5rem 0 .5rem 0.5rem
}

.nav_logo {
    margin-bottom: 2rem;
}

.nav_logo-icon {
    font-size: 1.25rem;
    color    : var(--white-color)
}

.nav_logo-name {
    color      : var(--white-color);
    font-weight: 700
}

.nav_link {
    position     : relative;
    color        : var(--first-color-light);
    margin-bottom: 1.5rem;
    transition   : .3s;
    padding-left : 1rem;
}

.nav_link:hover {
    color: var(--dark-color)
}

.nav_icon {
    font-size: 2rem
}

.show-nav-bar {
    right: 0
}

.body-pd {
    padding-right: calc(var(--nav-width) + 0rem)
}

.body-pd-header {
    padding-right: calc(var(--nav-width) + 1rem)
}

.active {
    color: var(--white-color)
}

.active::before {
    content         : '';
    position        : absolute;
    left            : 0;
    width           : 2px;
    height          : 32px;
    background-color: var(--white-color)
}

.height-100 {
    height: 100vh
}

#contLogo {
    background: #FFFFFF;
    padding   : 5px 80px;
}

#contLogoDos {
    display        : flex;
    background     : #b3b3b3;
    align-items    : center;
    justify-content: center;
    margin-left    : 10px;
    width          : 100px;
}

#contNotificaciones {
    display        : flex;
    background     : #b3b3b3;
    align-items    : center;
    justify-content: center;
    margin-left    : 10px;
    width          : 40px;
}

#btnMenuZonaUsuario {
    background   : #bac8ef;
    margin-left  : 10px;
    color        : #373737;
    border       : 0;
    border-radius: 0px;
}

.company-logo {
    max-width: 200px;
    display  : block;
}

.cont-company-info {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.dropdown-menu {
    min-width: 400px !important;
    transform: translate3d(32px, 50px, 0px) !important;
}

.links-header {
    color: #373737;
    cursor: pointer;
}

.links-header:hover {
    color: #000000;
}

.cursor-action {
    cursor: pointer;
}

.leaflet-right {
    right: 0px !important;
}

.secod-nav {
    background: #FFFFFF;
    height    : 100%;
    width     : 400px;
}

@media screen and (min-width: 768px) {
    body {
        /* margin      : calc(var(--header-height) + 1rem) 0 0 0; */
        margin       : 0 0 0 0;
        padding-right: calc(var(--nav-width))
    }

    .header {
        height : calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 2rem;
    }

    .header_img {
        width : 50px;
        height: 50px
    }

    .header_img img {
        width: 50px
    }

    .l-navbar {
        right  : 0;
        padding: 1rem 0 0 0
    }

    .show-nav-bar {
        width: calc(var(--nav-width) + 156px)
    }

    .body-pd {
        padding-right: calc(var(--nav-width) + 156px)
    }

    .body-pd-header {
        padding-right: calc(var(--nav-width) + 12rem)
    }
}

/* Nuevos estilos */

#header {
    background: var(--folia-color-white-100);
}

#contMenu {
    gap: 40px;
}

.header-title {
    color         : var(--folia-color-primary-text);
    font-weight   : 500;
    letter-spacing: 1.4px;
}

#contLangSelect {
    display       : inline-flex;
    gap           : 16px;
    letter-spacing: 1.4px;
}

.langSelect {
    color      : var(--folia-color-primary-text-2);
    font-weight: 900;
}

.langNotSelect {
    color      : var(--folia-color-primary-text-2-not-active);
    font-weight: 500;
}

#contZonaUsuario {
    padding    : 8px 20px;
    margin-left: 39px;
}

/* Estilos base para dispositivos móviles */
@media only screen and (max-width: 768px) {
    #header {
        display: none;
    }
}