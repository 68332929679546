.page-container {
    display: flex;
    align-items: center; /* Centrar verticalmente */
    justify-content: center; /* Centrar horizontalmente */
    height: 100%;
    margin-top: 80px;
}

.card {
    max-width: 400px;
    width: 100%;
    margin: 60px 20px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px; /* Ajustar según sea necesario */
}

.custom-button {
    background-color: #0066cc; /* Color de fondo */
    border-radius: 14px; /* Bordes redondos */
    color: white; /* Color del texto */
    padding: 10px 50px; /* Ajustar según sea necesario */
    cursor: pointer; /* Cambiar el cursor al pasar el ratón */
    transition: background-color 0.3s, border-color 0.3s; /* Transición suave */
}

.custom-button:hover {
    background-color: #0066cc;
}

input,
textarea,
button {
    margin: 5px 0px;
}

#contTogglePassword {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin         : 5px 0;
    width          : 30px;
    background     : #cbcbcb;
}