.font-size-12 {
    font-size: 12px;
}

#contSidePanelInfo {
    z-index   : 1000 !important;
    position  : absolute;
    background: #ffffff;
    right     : 0px;
    height    : 100%;
    width     : 0px;
    transition: 0.5s;
}

#sidePanelInfo,
#contPanePredio,
#contPanelListFolders,
#contPanelListPredios,
#contPanelFilterPredios,
#contPanelListDetailPredio {
    display: none;
}

#contPanePredio,
#contPanelListFolders,
#contPanelListPredios,
#contPanelFilterPredios {
    padding: 20px 30px;
}

#contPanelFilterPredios {
    background: #ffffff;
    position  : absolute;
    width     : 100%;
    overflow  : auto;
    height    : 98%;
}

.item-box-filter {
    border     : solid 2px #b2b2b2;
    display    : flex;
    padding    : 10px 10px;
    align-items: center;
    width      : 100%;
    cursor     : pointer;
}

.btn-filter {
    text-align : center;
    background : #e6e6e6;
    padding    : 10px 10px;
    align-items: center;
    width      : 100%;
    cursor     : pointer;
}

#dirNameFolder,
#dirNameSubFolder {
    color: #9dc8ea;
}

#dirNamePredio {
    color: #c6c6ca;
}

.btn-card-inner {
    padding: 10px 10px;
    cursor : pointer;
}

.btn-active {
    background-color: #0b99ff;
    color           : #ffffff;
}

.btn-not-active {
    background-color: #e6e6e6;
    color           : #474747;
}

.cont-card-folder {
    display       : flex;
    flex-direction: column;
    max-height    : calc(80vh - var(--header-height));
    overflow      : auto;
}

.cont-card-predios {
    display       : flex;
    flex-direction: column;
    max-height    : calc(70vh - var(--header-height));
    overflow      : auto;
}

.card-folder {
    background-color: #e6e6e6;
    margin-top      : 10px;
    margin-right    : 0px;
}

.dates-box {
    font-size: 12px;
}

.item-circle {
    background-color: #b0b0b0;
    width           : 25px;
    height          : 25px;
    border-radius   : 25px;
    margin-right    : 25px;
}

.item-circle-minor {
    background-color: #b0b0b0;
    width           : 15px;
    height          : 15px;
    border-radius   : 15px;
    margin-right    : 15px;
}

.item-circle-red {
    background-color: #ed4d2a;
    width           : 25px;
    height          : 25px;
    border-radius   : 25px;
    margin-right    : 25px;
}

.item-circle-yellow {
    background-color: #f9ce29;
    width           : 25px;
    height          : 25px;
    border-radius   : 25px;
    margin-right    : 25px;
}

.item-circle-blue {
    background-color: #56b8ff;
    width           : 25px;
    height          : 25px;
    border-radius   : 25px;
    margin-right    : 25px;
}

.item-circle-green {
    background-color: #14ae5c;
    width           : 25px;
    height          : 25px;
    border-radius   : 25px;
    margin-right    : 25px;
}

.thumbnail-predio {
    background-color: #b4b4b4;
    width           : 50px;
    height          : 50px;
    margin          : 0 2px;
}

.thumbnail-predio img {
    width: -webkit-fill-available;
}

.box-count-predios,
.text-count-predios {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.icon-list-predios {
    font-size   : 25px;
    margin-right: 5px;
    cursor      : pointer;
}

/* DIV DETALLE PREDIO */
.cont-header-detail-predio {
    padding: 20px 30px;
}

.cont-card-detail-predio {
    display       : flex;
    flex-direction: column;
    max-height    : calc(62vh - var(--header-height));
    overflow-y    : auto;
    overflow-x    : hidden;
    padding       : 0px 10px 20px 30px;
}

.cont-next-eval {
    background-color: #e6e6e6;
    border-radius   : 4px;
    padding         : 5px;
}

.btn-download {
    display         : flex;
    justify-content : center;
    background-color: #b3b3b3;
    color           : #000000;
    font-size       : 25px;
    padding         : 5px;
    max-width       : fit-content;
    border-radius   : 5px;
}

.cont-last-report {
    background-color: #e6e6e6;
    border-radius   : 4px;
}

.cont-label-valid {
    background-color: #cccccc;
    font-size       : 12px;
    border-radius   : 4px;
    text-align      : center;
}

.cont-label-valid-green {
    background-color: #14ae5c;
    font-size       : 12px;
    border-radius   : 4px;
    text-align      : center;
}

.icon-free-defores {
    max-width: 25px
}

.text-free-defores {
    color      : #14ae5c;
    margin-left: 10px;
}

.text-medium-defores {
    color      : #aeab14;
    margin-left: 10px;
}

.text-high-defores {
    color      : #ae2b14;
    margin-left: 10px;
}

.label-deforest-history {
    background-color: #14ae5c;
    color           : #ffffff;
    padding         : 2px 10px;
    border-radius   : 10px;
    border          : 3px solid #108b4a;
}

.label-deforest-history-free {
    background-color: #14ae5c;
    color           : #ffffff;
    padding         : 2px 10px;
    border-radius   : 10px;
    border          : 3px solid #108b4a;
}

.label-deforest-history-medium {
    background-color: #aeab14;
    color           : #ffffff;
    padding         : 2px 10px;
    border-radius   : 10px;
    border          : 3px solid #aeab14;
}

.label-deforest-history-high {
    background-color: #ae2b14;
    color           : #ffffff;
    padding         : 2px 10px;
    border-radius   : 10px;
    border          : 3px solid #ae2b14;
}

/* PopUps Predios */

.cont-popup-predio {
    min-width: 300px;
    color    : #1e1e1e;
}

.title-popup-predio {
    font-size    : 20px;
    font-weight  : bold;
    margin-bottom: 18px;
}

.icon-popup {
    max-width: 50px
}

.cont-item-popup {
    margin-bottom: 8px;
}

.item-popup {
    font-size: 15px;
}

.cont-left-popup {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : flex-end;
}

.btn-popup {
    background-color: #0d99ff;
    border          : 0;
    padding         : 5px 15px;
    color           : #ffffff;
    border-radius   : 5px;
    font-size       : 15px;
}

/* Vista Alertas */
.card-alert {
    background-color: #ffd394;
    margin-top      : 10px;
    margin-right    : 0px;
}

.card-alert-high {
    background-color: #ffd8d4;
    margin-top      : 10px;
    margin-right    : 0px;
}

.card-alert-medium {
    background-color: #ffd394;
    margin-top      : 10px;
    margin-right    : 0px;
}

.card-alert-low {
    background-color: #e7e7e7;
    margin-top      : 10px;
    margin-right    : 0px;
}

.icon-card-alert-medium {
    color           : #ffffff;
    background-color: #ffa629;
    margin          : 0;
    padding         : 0;
}

.icon-card-alert-high {
    color           : #ffffff;
    background-color: #ed4d2a;
    margin          : 0;
    padding         : 0;
}

.icon-card-alert-low {
    color           : #ffffff;
    background-color: #e7e7e7;
    margin          : 0;
    padding         : 0;
}

.card-last-alert {
    background-color: #ffd8d4;
    margin-top      : 10px;
    margin-right    : 0px;
}

.card-last-alert-no {
    background-color: #e7e7e7;
    margin-top      : 10px;
    margin-right    : 0px;
}

.card-last-alert-high {
    background-color: #ffd8d4;
    margin-top      : 10px;
    margin-right    : 0px;
}

.card-last-alert-medium {
    background-color: #ffcd29;
    margin-top      : 10px;
    margin-right    : 0px;
}

.card-last-alert-low {
    background-color: #e7e7e7;
    margin-top      : 10px;
    margin-right    : 0px;
}

.icon-last-alert {
    color           : #ffffff;
    background-color: #f24822;
    margin          : 0;
    padding         : 0;
}

.icon-last-alert-high {
    color           : #ffffff;
    background-color: #f24822;
    margin          : 0;
    padding         : 0;
}

.icon-last-alert-medium {
    color           : #ffffff;
    background-color: #ffcd29;
    margin          : 0;
    padding         : 0;
}

.icon-last-alert-low {
    color           : #ffffff;
    background-color: #e7e7e7;
    margin          : 0;
    padding         : 0;
}

.card-interactions-alert {
    background-color: #d9d9d9;
    margin-right    : 0px;
}

.card-interaction {
    background-color: #ffffff;
    border-radius   : 5px;
}

.input-interaction {
    border       : 0px;
    border-radius: 15px;
}

/* Dash Home */
.conts-dash-home {
    height: 100%;
}

.cont-dash {
    background: #f6f6f6;
    position  : fixed;
    height    : calc(100% - var(--header-height));
    top       : calc(15px + var(--header-height));
    width     : 100%;
}

.cont-dash>.row {
    margin-left : 0 !important;
    margin-right: 0 !important;
}

.cont-panels {
    height: 100%;
}

.item-dash {
    background     : #e6e6e6;
    margin         : 30px 0 0 30px;
    padding        : 20px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    text-align     : center;
}

.doble-item-dash {
    width: calc(50% + 30px) !important;
    background: #366de6;
}

.item-properties {
    background: #25df6c;
}

.item-reports {
    background: #dbbb06;
}

.item-alerts {
    background: #d34444;
}

#btnSeeMap {
    background   : #757575 !important;
    border-radius: 15px !important;
}

.cont-btn-side-left-dash {
    display           : flex;
    flex-direction    : column;
    justify-content   : center;
    /* height         : calc(100vh - var(--header-height)); */
}

.btn-side-left-dash {
    background-color: #ffffff;
    border          : 1px solid #757575;
    padding         : 10px 0 10px 35px;
    color           : #0d99ff;
    border-radius   : 5px;
    font-size       : 15px;
    max-width       : 150px;
    text-align      : left;
}

.btn-side-left-dash-selected {
    background-color: #0d99ff;
    color           : #ffffff;
}

/* Home */
.cont-home {
    background   : var(--folia-color-background-blue);
    position     : fixed;
    /* height    : calc(98% - var(--header-height)); */
    top          : calc(15px + var(--header-height));
    width        : 100%;
    height       : 100%;
    overflow     : auto;
}

.cont-home>.row {
    margin-left : 0 !important;
    margin-right: 0 !important;
}

.home-home-title {
    font-weight: bold;
    font-size  : 53px;
}

.home-regulation-cont {
    display           : flex;
    flex-direction    : column;
    /* justify-content: center; */
    min-height        : calc(100vh - var(--header-height));
}

.home-regulation-paragraph {
    font-weight: bold;
    font-size  : 25px;
}

.home-solutions-card-body-s2>ul {
    list-style-type: none;
    padding-left   : 0;
}

.home-solutions-card-body-s2>ul>ul {
    list-style-type: none;
    padding-left   : 20px;
}

.home-solutions-btn {
    background-color: #757575;
    border          : 0px solid #ffffff;
    padding         : 10px 5px;
    color           : #ffffff;
    border-radius   : 2px;
    font-size       : 15px;
    min-width       : 150px;
    text-align      : center;
}

.cont-tab-testimonios {
    background-color: #f1f1f1;
    padding         : 30px 40px;
}

.cont-next-testimonio {
    display    : flex;
    align-items: center;
}

.cont-logos-testimonio {
    display        : flex;
    justify-content: center;
}

.cont-logo-testimonio {
    background-color: #e6e6e6;
    border          : 2px solid #b5b5b5;
    padding         : 5px 20px;
    color           : #b5b5b5;
    font-size       : 20px;
    width           : 15%;
    margin          : 0 2.5%;
    text-align      : center;
}

/* Nuevos estilos */

.cont-btn-side-left-home {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    height         : calc(100vh - var(--header-height));
    position       : fixed;
    z-index        : 1;
}

.btn-side-left-home {
    background-color: transparent;
    color           : var(--folia-color-white-100);
    border          : 0px;
    padding         : 8px 0px;
    max-width       : 100px;
    display         : flex;
    gap             : 10px;
    align-items     : center;
}

.btn-side-left-home-selected>span {
    border-bottom: 1px solid;
    font-weight  : bold !important;
}

.top-header-white {
    position  : absolute;
    left      : 0;
    width     : 100%;
    height    : 145px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
}

.home-home-cont {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    min-height     : calc(100vh - var(--header-height));
}

#bannerFondo {
    width     : 100vw;
    height    : 105vh;
    position  : absolute;
    object-fit: cover;
}

.cont-home-cont {
    position: relative;
}

.home-news-cont {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    min-height     : calc(100vh - var(--header-height));
}

.home-news-card-header {
    max-width: 378px;
}

.home-news-card-header>img {
    max-width    : 100%;
    border-radius: 8px 8px 0px 0px;
}

.home-news-card-tag {
    padding      : 4px 8px;
    position     : absolute;
    right        : 24px;
    top          : 24px;
    border-radius: 8px;
}

.card-tag-green {
    background: var(--folia-color-tag-green);
}

.card-tag-orange {
    background: var(--folia-color-tag-orange);
}

.card-tag-blue {
    background: var(--folia-color-tag-blue);
}

.home-news-by-folia {
    padding      : 4px 0px;
    position     : absolute;
    left         : 0px;
    bottom       : 19px;
    border-radius: 0px 8px 8px 0px !important;
}

.home-news-card-body {
    background    : var(--folia-color-primary-text);
    padding       : 24px;
    border-radius : 0px 0px 8px 8px;
    display       : inline-flex;
    flex-direction: column;
    gap           : 16px;
    min-height    : 455px;
    max-width     : 378px;
}

.home-news-card-body:hover {
    background: var(--folia-color-secondary-text);
}

.home-news-card-body-s1 {
    font-size      : 12px;
    display        : flex;
    justify-content: space-between;
}

.date-news {
    color  : var(--folia-color-secondary-text-blue-light) !important;
    padding: 2px 0px;
}

.home-news-card-body-s1>div {
    padding         : 2px 10px;
    background-color: #b1b1b1;
}

.p-new-description {
    color: var(--folia-color-secondary-text-blue-light) !important;
}

.home-news-card-body-s4 {
    display    : inline-flex;
    align-items: flex-end;
    gap        : 8px;
}

.author-new {
    color: var(--folia-color-secondary-text-blue-light) !important;
}

.testimony-new {
    color: var(--folia-color-tag-blue  ) !important;
}

#News-cont {
    background-image: url(../assets/images/background/background-noticias.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}

#Experience-cont {
    background-image   : url(../assets/images/background/background-nuestro-trabajo.png);
    background-repeat  : no-repeat;
    background-position: center top;
    padding-bottom     : 100px;
    background-size: cover;
}

.home-experience-title-a {
    border-bottom: 3px solid var(--folia-color-accent);
}

.home-experience-cont-card {
    text-align     : center;
    border-radius  : 8px;
    background     : var(--folia-color-secondary-text-50);
    padding        : 12px;
    min-height     : 290px;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

#Regulation-cont {
    background-image   : url(../assets/images/background/background-regulacion.png);
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center top;
}

.cont-icons-regulation {
    min-height      : 182px;
    background-color: var(--folia-color-white-20);
    display         : flex;
    justify-content : center;
    border-radius   : 8px;
}

.col-regulation {
    width: 210px;
}


.home-solutions-cont {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    min-height     : calc(100vh - var(--header-height));
    width: 86%;
}

.home-solutions-card-header {
    background-color: var(--folia-color-secondary-text-blue-light);
    text-align: center;
    border-radius: 8px 8px 0px 0px;
    min-height: 20px;
    line-height: 20px;
}

.card-header-reco {
    background-color: var(--folia-color-positive);
}

.home-solutions-card-picture {
    background-color: var(--folia-color-secondary-text-dark-blue);
    text-align      : center;
}

.home-solutions-card-logo {
    padding: 20px 0;
}

.home-solutions-card-picture-reco {
    background-color: var(--folia-color-white-100);
}

.home-solutions-card-body {
    background-color: var(--folia-color-secondary-text-dark-blue);
    color           : var(--folia-color-white-100);
    padding         : 15px;
    border-radius   : 0 0 8px 8px;
}

.txt-card-body-not {
    color: var(--folia-color-white-60);
}

.card-body-reco {
    background-color: var(--folia-color-secondary-text-50);
}

.home-solutions-card-body-s1 {
    text-align: center;
}

.home-solutions-card-body-s3 {
    text-align: center;
}

#ContactUs-cont {
    background-image : url(../assets/images/background/background-contacto.png);
    background-repeat: no-repeat;
    background-size  : cover;
}

.home-contactus-cont {
    min-height: calc(100vh - var(--header-height));
}

.home-contactus-cont-card {
    background-color: var(--folia-color-background-blue-70);
    backdrop-filter : blur(50px);
    border-radius   : 8px;
    padding         : 40px 30px;
    min-height      : 380px;
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
}

.home-contactus-cont-card-lignt {
    background-color: var(--folia-color-white-80);
}

.home-contactus-text-lignt {
    color: var(--folia-color-background-blue) !important;
}

#footer-home {
    /* min-height      : calc(50vh - var(--header-height)); */
    background-color: var(--folia-color-background-blue);
    position        : absolute;
    z-index         : 2;
    width           : 100%;
    padding-bottom  : 100px;
}

.col-footer {
    flex : 0 0 auto;
    width: 12.5%;
}

.title-footer {
    margin-bottom: 80px;
}

.txt-sub-footer {
    color: var(--folia-color-secondary-text-blue-light) !important;
}

.cont-social-icons {
    display        : flex;
    justify-content: space-between;
}

.home-news-card-body-how {
    background    : var(--folia-color-primary-text-70);
    border-radius : 0px 0px 8px 8px;
    display       : inline-flex;
    flex-direction: column;
    gap           : 16px;
    min-height    : 455px;
    max-width     : 378px;
}

.home-experience-card-body-s1 {
    padding: 24px 18px 10px 18px;
}

.home-news-card-body-s2 {
    padding: 10px 18px 24px 18px;
}

.green-check::before {
    content: "✓";
    color: green;
    margin-right: 2px;
    font-size: 1.5em;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 20px; 
}

.video-container iframe {
    width: 100%;
    max-width: 1000px; 
    height: 450px; 
}


/* Estilos base para dispositivos móviles */
@media only screen and (max-width: 768px) {
    html,
    body,
    #root,
    .App {
        overflow-x: hidden;
    }

    /* Ajustes para el contenedor principal */
    .cont-home {
        position: relative; /* Cambia de fixed a relative para evitar problemas de visualización */
        top: 0; /* Restablece la posición superior */
        height: auto; /* Altura automática para adaptarse al contenido */
        overflow: visible; /* Permite que el contenido se desborde si es necesario */
    }
    

    /* Ajustes para los títulos y textos */
    .home-home-title {
        font-size: 4px; /* Tamaño de fuente más pequeño para el título principal */
        margin: 20px 0; /* Añade márgenes para evitar que el texto toque los bordes */
    }

    .home-regulation-paragraph {
        font-size: 18px; /* Reduce el tamaño de fuente para los párrafos */
    }

    /* Ajustes para los botones laterales */
    .cont-btn-side-left-home {
        display: none; /* Cambia la visualización a block para que los botones se apilen */
    }

    .btn-side-left-home {
        font-size: 14px; /* Reduce el tamaño de la fuente de los botones */
        padding: 8px; /* Reduce el padding */
        max-width: none; /* Elimina el ancho máximo */
    }

    /* Ajustes para el banner y otros contenidos */
    #bannerFondo {
        width     : 100vw;
        height    : 105vh;
        position  : absolute;
        object-fit: cover;
        
    }

    .home-news-card-header,
    .home-news-card-body,
    .home-experience-cont-card,
    .home-solutions-card-body {
        width: 100%; /* Ajusta los elementos para que ocupen todo el ancho disponible */
        max-width: none; /* Elimina cualquier ancho máximo previamente establecido */
    }

    .home-news-cont {
        padding: 20px; /* Reduce el padding para dispositivos más pequeños */
    }

    .home-news-cont .row {
        margin: 0; /* Elimina margen extra que pueda estar afectando la disposición */
    }

    .home-news-cont .col-4 {
        width: 100%; /* Hace que cada tarjeta tome el ancho completo en móviles */
        margin-bottom: 20px; /* Agrega espacio entre las tarjetas */
    }

    /* Ajustes para las fuentes y los elementos dentro de las tarjetas */
    .folia-font, .folia-font-m, .folia-font-s, .folia-font-xs {
        font-size: reduce a un tamaño apropiado para móviles; /* Puedes especificar un tamaño exacto */
    }

    /* Botones y otros controles */
    .folia-button {
        width: 100%; /* Botones de ancho completo para fácil acceso */
        padding: 12px; /* Más padding para un mejor toque en pantallas táctiles */
    }

    /* Imágenes y encabezados dentro de las tarjetas */
    .home-news-card-header img {
        width: 100%; /* Asegura que las imágenes se escalen correctamente */
    }

    .home-experience-cont .row {
        flex-direction: column; /* Cambia la dirección de las filas a columnas */
    }

    .home-experience-cont .col-4 {
        width: 100%; /* Cada columna toma el ancho completo del dispositivo */
        margin-bottom: 20px; /* Agrega espacio entre tarjetas */
    }

    .home-experience-cont-card {
        padding: 10px; /* Reduce el padding dentro de cada tarjeta para ahorrar espacio */
    }

    .folia-font-l, .folia-font-m, .folia-font-s, .folia-font-xs {
        font-size: smaller; /* Reduce el tamaño de la fuente en todos los componentes de texto */
    }

    .home-experience-title-a {
        font-size: 18px; /* Especifica un tamaño de fuente más pequeño para los títulos */
    }

    /* Ajustes para los botones o enlaces clicables */
    .folia-button, .home-experience-title-a {
        padding: 10px; /* Ajusta el padding para hacerlos más táctiles */
        display: block; /* Hace que los elementos de enlace se muestren como bloques */
        width: 100%; /* Aprovecha todo el ancho disponible */
        text-align: center; /* Centra el texto dentro del enlace o botón */
    }

    /* Imágenes */
    .home-experience-cont-card img {
        height: auto; /* Mantiene la proporción de las imágenes */
    }


    .home-solutions-cont .row {
        margin: 0;
    }

    .home-solutions-cont .col-3 {
        width: 100%; /* Hace que cada tarjeta tome el ancho completo */
        padding: 0 1px;
        margin-bottom: 20px; /* Agrega espacio entre las tarjetas */
    }

    .home-solutions-card-body {
        padding: 15px; /* Ajusta el padding para asegurar que el texto no esté demasiado apretado */
    }

    .home-solutions-card-logo, .home-solutions-card-picture img {
        width: 100%; /* Asegura que las imágenes se ajusten correctamente al ancho de la tarjeta */
        height: auto; /* Mantiene la proporción de las imágenes al ajustar el ancho */
    }

    .folia-font-xl, .folia-font-l, .folia-font-m, .folia-font-s {
        font-size: reduce a un tamaño apropiado para móviles; /* Ajusta el tamaño de fuente para mejorar la legibilidad */
    }

    .folia-button {
        width: 100%; /* Botones de ancho completo para fácil acceso */
        padding: 12px; /* Más padding para un mejor toque en pantallas táctiles */
    }

    .home-contactus-cont .col-6, .home-contactus-cont .col-4 {
        width: 100%; /* Hace que cada tarjeta tome el ancho completo en dispositivos móviles */
        margin-bottom: 20px; /* Espacio entre tarjetas */
    }

    .home-contactus-card-body, .home-contactus-card-footer {
        padding: 10px; /* Ajusta el padding para un mejor uso del espacio */
    }

    .folia-font-xl, .folia-font-l, .folia-font-s {
        font-size: reduce el tamaño según sea necesario; /* Ajusta el tamaño de fuente para una mejor legibilidad */
    }

    .folia-button {
        width: auto; /* Opción para ajustar el ancho del botón según el contenido */
        padding: 12px 20px; /* Aumenta el padding para un mejor toque en pantallas táctiles */
    }


    .col-footer {
        width: 100%; /* Establece el ancho al 100% para cada columna */
        margin-bottom: 20px; /* Agrega espacio entre cada sección del pie de página */
    }

    .home-contactus-card-header, .home-contactus-card-body, .home-contactus-card-footer {
        padding: 15px; /* Ajusta el padding para un mejor uso del espacio */
    }

    .folia-font-s, .folia-font-xs {
        font-size: adjust to smaller sizes; /* Reduce el tamaño de la fuente según sea necesario */
    }

    .cont-social-icons img {
        width: 50px; /* Establece un tamaño fijo para los iconos de redes sociales */
        height: auto; /* Mantiene la proporción de las imágenes */
        margin-right: 10px; /* Espacio entre iconos */
    }

    .logoWhiteDreamgis, .logoApoyosFooter {
        width: 100%; /* Ajusta el ancho de los logotipos al 100% para adaptarlos al tamaño del dispositivo */
        height: auto; /* Ajusta automáticamente la altura para mantener la proporción */
    }

    .col-8 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    .logo-powered-by {
        width: 40%; /* Aumenta el tamaño del logo 'Powered by' */
        margin: 0 auto; /* Centra el logo horizontalmente */
        display: block; /* Asegura que el logo se muestre correctamente */
    }

    .logo-support {
        width: 80%; /* Reduce el tamaño del logo 'Con el apoyo de' */
        margin: 0 auto; /* Centra el logo horizontalmente */
        display: block; /* Asegura que el logo se muestre correctamente */
    }

    .partners-container {
        display: none;
    }
}
