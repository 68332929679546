.card {
    padding      : 30px 40px;
    margin-top   : 60px;
    margin-bottom: 60px;
    border       : none !important;
    box-shadow   : 0 6px 12px 0 rgba(0, 0, 0, 0.2)
}

input,
textarea,
button {
    margin: 5px 0px;
}

#contTogglePassword {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin         : 5px 0;
    width          : 30px;
    background     : #cbcbcb;
}