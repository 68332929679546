html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh; /* 100% del viewport height */
  width: 100vw; /* 100% del viewport width */
  overflow-x: hidden; /* Oculta el desbordamiento horizontal */
}



:root {
  --folia-color-accent                   : #0061FF;
  --folia-color-accent-20                : rgba(0, 97, 255, 0.20);
  --folia-color-positive                 : #00BF5E;
  --folia-color-primary-text             : #192133;
  --folia-color-primary-text-2           : #061437;
  --folia-color-primary-text-2-not-active: #BAC8EF;
  --folia-color-primary-text-70          : rgba(25, 33, 51, 0.70);
  --folia-color-white-100                : #FFF;
  --folia-color-white-80                 : rgba(255, 255, 255, 0.80);
  --folia-color-white-60                 : rgba(255, 255, 255, 0.60);
  --folia-color-white-40                 : rgba(255, 255, 255, 0.40);
  --folia-color-white-20                 : rgba(255, 255, 255, 0.20);
  --folia-color-background-blue          : #0B1F41;
  --folia-color-background-blue-70       : rgba(11, 31, 65, 0.70);
  --folia-color-secondary-text           : #384E6D;
  --folia-color-secondary-text-50        : rgba(56, 78, 109, 0.50);
  --folia-color-secondary-text-tension   : #36D953;
  --folia-color-secondary-text-blue      : #3D6DD2;
  --folia-color-secondary-text-blue-light: #A8B0CA;
  --folia-color-secondary-text-dark-blue : #13294F;
  --folia-color-tag-green                : #34AA58;
  --folia-color-tag-orange               : #E26C00;
  --folia-color-tag-blue                 : #195DE0;
  --folia-color-recommended              : #E4E6EA;
  --folia-font-family                    : "Proxima Nova"
}

/* Fonts */

.folia-font {
  color         : var(--folia-color-white-100);
  /* font-family: var(--folia-font-family); */
  font-style    : normal;
}

/* Titles */

.folia-font-xxl-bold {
  font-size     : 80px;
  font-weight   : 700;
  line-height   : 120%;
  letter-spacing: -1.6px;
}

.folia-font-xl {
  font-size  : 64px;
  line-height: 120%;
}

.folia-font-xl-bold {
  font-weight   : 700;
  letter-spacing: -1.28px;
}

.folia-font-xl-light {
  font-weight   : 300;
  letter-spacing: -1.28px;
}

.folia-font-xl-light-small {
  font-size     : 48px !important;
  font-weight   : 300;
  letter-spacing: -0.96px;
}

/* Sub-Titles */

.folia-font-l {
  font-size  : 24px;
  line-height: 130%;
}

.folia-font-l-bold {
  font-size  : 32px !important;
  font-weight: 700;
}

.folia-font-l-bold-small {
  font-weight: 700;
}

.folia-font-l-regular-small {
  font-weight: 500;
  line-height: 150% !important;
}

/* Descriptions */

.folia-font-m {
  font-size  : 20px;
  line-height: 130%;
}

.folia-font-m-semibold {
  font-weight: 600;
}

.folia-font-m-semibold-tracking {
  font-weight   : 600;
  letter-spacing: 1px;
}

.folia-font-m-medium-italic {
  font-style : italic !important;
  font-weight: 500;
}

/* Bodys */

.folia-font-s {
  font-size  : 16px;
  line-height: 130%;
}

.folia-font-s-bold {
  font-weight: 700;
}

.folia-font-s-regular {
  font-weight: 400;
}

.folia-font-s-regular-tracking {
  font-weight   : 400;
  letter-spacing: 1.6px;
}

.folia-font-xs {
  font-size  : 14px;
  line-height: 130%;
}

.folia-font-xs-bold {
  font-weight: 700;
}

.folia-font-xs-semibold-tracking {
  font-weight   : 600;
  letter-spacing: 1.4px;
}

.folia-font-xs-medium {
  font-weight: 500;
}

.folia-font-xs-medium-italic {
  font-style : italic !important;
  font-weight: 500;
}

.folia-font-xxs-semibold {
  font-size  : 12px;
  font-weight: 600;
  line-height: 130%;
}

/* Fin Fonts */

/* Botones */

.folia-button {
  display           : inline-flex;
  padding           : 16px 24px;
  justify-content   : flex-end;
  align-items       : flex-start;
  gap               : 10px;
  border            : 0;
  border-radius     : 8px;
  text-align        : center;
  /* font-family    : Lato; */
  font-size         : 16px;
  font-style        : normal;
  font-weight       : 500;
  line-height       : 150%;
  letter-spacing    : 1.6px;
}

.folia-button-loud-blue {
  background: var(--folia-color-accent);
  color     : var(--folia-color-white-100);
}

.folia-button-loud-white {
  background: var(--folia-color-white-100);
  color     : var(--folia-color-accent);
}

.folia-button-quiet {
  background: var(--folia-color-accent-20);
  color     : var(--folia-color-accent);
}

.folia-button-quiet-dark {
  background: var(--folia-color-white-20);
  color     : var(--folia-color-white-100);
}

.folia-button-transparent {
  background: transparent;
  color     : var(--folia-color-accent);
}

.folia-button-large {
  background: var(--folia-color-accent);
}

.folia-button-medium {
  background    : var(--folia-color-accent);
  color         : var(--folia-color-white-100);
  padding       : 8px 16px !important;
  font-family   : "Proxima Nova" !important;
  letter-spacing: normal !important;
}

.folia-button-samll {
  background    : var(--folia-color-accent);
  color         : var(--folia-color-white-100);
  padding       : 4px 8px !important;
  font-family   : "Proxima Nova" !important;
  letter-spacing: normal !important;
  font-size     : 14px !important;
}

/* Fin Botones */

@media only screen and (max-width: 768px) {
  .App {
    overflow-x: hidden;
  }

  .cont-home {
    position: relative;
    top: 0;
    height: auto;
    overflow: visible;
  }

  /* Ajustes de fuente para dispositivos móviles */
  .folia-font-xl, .folia-font-xl-bold, .folia-font-xl-light, .folia-font-xl-light-small {
    font-size: calc(100% + 1vw); /* Escala el tamaño de la fuente basado en el ancho de la pantalla */
    letter-spacing: 1px;
  }

  .folia-font-xxl-bold{
    font-size: calc(100% + 1vw); /* Escala el tamaño de la fuente basado en el ancho de la pantalla */
    letter-spacing: 1.8px;
  }

  .folia-button {
    padding: 12px 18px; /* Botones más pequeños para pantallas más pequeñas */
  }

  /* Ajustar los botones para que se adapten mejor en pantallas pequeñas */
  .folia-button-large, .folia-button-medium, .folia-button-small {
    padding: 8px 12px;
    font-size: 12px;
  }
}
